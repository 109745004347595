import { ObjectShim } from '@packages/helpers/core/shims/object-shim';

export const isInputNested = (name, identifier) => name && identifier;

export const getUserAttributeTypeParts = userAttributeType => {
  if (!userAttributeType) {
    return {};
  }
  const [attributeType, name] = userAttributeType?.split('.');

  return { attributeType, name };
};

export const buildNestedAttributeValue = (inputData = {}, inputValueData = {}, mergeObject = {}) => {
  const { userAttributeType, userAttributeField, identifier } = inputData;
  const { value, staticPayload = {} } = inputValueData;
  const { name } = getUserAttributeTypeParts(userAttributeType);

  const newValue = isInputNested(name, identifier)
    ? {
        [name]: {
          [identifier]: {
            [userAttributeField]: value,
            ...staticPayload
          }
        }
      }
    : {
        [userAttributeField]: value,
        ...staticPayload
      };

  return ObjectShim.mergeDeep(mergeObject, newValue);
};

export const getNestedAttributeValue = ({ userAttributeType, userAttributeField, identifier }, state) => {
  const { attributeType, name } = getUserAttributeTypeParts(userAttributeType);

  return isInputNested(name, identifier)
    ? state?.[attributeType]?.[name]?.[identifier]?.[userAttributeField]
    : state?.[attributeType]?.[userAttributeField];
};

export const getNestedAttributePrevState = ({ userAttributeType, identifier }, state) => {
  const { attributeType, name } = getUserAttributeTypeParts(userAttributeType);

  return isInputNested(name, identifier) ? state?.[attributeType]?.[name] ?? {} : {};
};
