import React from 'react';
import classnames from 'classnames';
import { defaultProps } from 'recompose';
import styles from '../../styles/components/form-explainer.module.scss';
import { PurifiedHtmlContainer } from '../layout/purified-html-container';

const Explainer = React.memo(({ className, tabIndex, role, html, explainerId = html }) => (
  <PurifiedHtmlContainer
    renderAs='p'
    id={explainerId}
    tabIndex={tabIndex}
    role={role}
    className={classnames(styles['form-explainer'], className)}
    html={html}
  />
));

const FormExplainer = defaultProps({ tabIndex: 0, role: 'term' })(Explainer);

Explainer.displayName = 'Explainer';

export { Explainer, FormExplainer };
